import { DateTime } from 'luxon'

export default () => ({
  id: '00000000-0000-0000-0000-000000000000',
  companyKey: 0,
  companyName: '',
  companyIdentification: '',
  companyIdentificationCheck: '',
  identificationTypeId: '31',
  identificationTypeCode: 'NIT',
  identificationTypeName: 'NIT',
  companyPhone: '',
  companyAddress: '',
  companyWebsite: '',
  companyEmail: '',
  companyStatus: '',
  companyState: '',
  companyZipCode: '',
  companyCity: '',
  timeZoneId: 'America/Bogota',
  languageId: 'es',
  currencyId: 'COP',
  countryId: 'CO',
  cultureId: 'es-CO',
  companyTrial: '',
  companyTrialEndDateUtc: '',
  companyLastLogin: '',
  companyTenant: '',
  companyApiKey: '',
  personTypeId: '1',
  regimeTypeId: '48',
  companyHasPriceList: '',
  companyHasVendor: '',
  companyHasShippingCharge: '',
  companyHasAdjustment: '',
  companyHasItemIncludeTax: '',
  companyDiscount: '',
  companyDiscountInvoice: '',
  companyPortal: '',
  companyLogo: '',
  features: [],
  addOns: [],
  companyServicePrice: 0,
  companyServiceFrequencyBilling: 'monthly',
  companyServiceBillingStartDate: DateTime.local().plus({ days: 16 }).toISODate(),
  companyServiceEndDateTrialPeriod: DateTime.local().plus({ days: 15 }).toISODate(),
  companyCountry: 'Colombia',
  companyDateFormat: 'MM-DD-YYYY',
  companyTimeFormat: 'HH:mm',
  featuresTakeThePlan: false,
  plan: {
    features: [],
  },

  /* ----------  User  ---------- */

  userFirstName: '',
  userLastName: '',
  userName: '',
  userEmail: '',
})
