import { i18n } from '@/i18n'

export default () => (
  {
    serviceId: [{ required: true, message: i18n.tc('required'), trigger: 'blur' }],
    planName: [{ required: true, message: i18n.tc('required'), trigger: 'blur' }],
    planPriceMonthly:
      [
        { type: 'number', message: i18n.tc('invalidNumber'), trigger: ['blur', 'change'] },
      ],
    planPriceYearly:
      [
        { type: 'number', message: i18n.tc('invalidNumber'), trigger: ['blur', 'change'] },
      ],
    planOrder:
      [
        { required: true, message: i18n.tc('required'), trigger: 'blur' },
        { type: 'number', message: i18n.tc('invalidNumber'), trigger: ['blur', 'change'] },
      ],
  }
)
