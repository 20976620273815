export default () => ({
  plan: {
    id: 0,
    serviceId: '',
    serviceName: '',
    planName: '',
    planMode: 'Periodicidad',
    planDescription: '',
    planQuantity: '',
    planPriceYearly: '',
    planPriceMonthly: '',
    planPriceQuarterly: '',
    planPriceSemiannual: '',
    planPriceYearlyNew: '',
    planPriceMonthlyNew: '',
    planPriceQuarterlyNew: '',
    planPriceSemiannualNew: '',
    promotionYearly: [],
    promotionMonthly: [],
    promotionQuarterly: [],
    promotionSemiannual: [],
    planTaxPercentIncrease: '',
    planTaxPercentIncluded: '',
    planFree: false,
    planOnSite: false,
    planIsDefault: false,
    planEnabled: true,
    planOrder: '',
    features: [],
  },
  service: {},
  reset() {
    const _this = this || {}
    _this.id = 0
    _this.serviceId = ''
    _this.serviceName = ''
    _this.planName = ''
    _this.planMode = 'Periodicidad'
    _this.planDescription = ''
    _this.planQuantity = ''
    _this.planPriceYearly = ''
    _this.planPriceMonthly = ''
    _this.planPriceQuarterly = ''
    _this.planPriceSemiannual = ''
    _this.promotionYearly = []
    _this.promotionMonthly = []
    _this.promotionQuarterly = []
    _this.promotionSemiannual = []
    _this.planTaxPercentIncrease = ''
    _this.planTaxPercentIncluded = ''
    _this.planFree = false
    _this.planOnSite = false
    _this.planIsDefault = false
    _this.planEnabled = true
    _this.planOrder = ''
    _this.features = []
  },
})

export const promotion = () => ({
  promotionType: 'FirstPeriods',
  promotionPeriodsQuantity: 0,
  promotionDiscountPercent: 0,
})
